import React from 'react';
import styled from 'styled-components';
import Page from '../components/Page';
import {PageProps} from '../interfaces';

import {H1, P1} from './../style/Typography';
import {fonts, colors, query} from './../style/theme';
import ConfirmationIcon from './../images/confirmation.svg';
import ButtonLink from './../components/ButtonLink';

interface RawPageProps {
  path: string;
  pageContext: object;
  pathContext: object;
}

const Container = styled.section`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: 100%;
  height: 60vh;
  min-height: 400px;

  @media (${query.above.tablet}) {
    height: 80vh;
  }

  @media (${query.above.small}) {
    height: calc(100vh - 236px);
  }
`;

const Inner = styled.div``;

const Headline = styled(H1)`
  ${fonts.sofiaBold};

  color: ${colors.payneGrey};
  font-size: 32px;
  letter-spacing: -0.5px;
  line-height: 34px;
  text-align: center;
  margin: 15px 25px 10px 25px;

  @media (${query.above.tablet}) {
    font-size: 60px;
    letter-spacing: -1px;
    line-height: 60px;
    margin: 30px 0 20px 0;
  }
`;

const Description = styled.div`
  ${fonts.sofiaRegular};

  color: ${colors.payneGrey};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin: 10px 25px 25px 25px;

  @media (${query.above.tablet}) {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 27px;
    margin-bottom: 30px;
  }
`;

const ConfirmationIconWrapper = styled.div`
  text-align: center;
`;

const StyledConfirmationIcon = styled(ConfirmationIcon)`
  width: 40px;
  height: 40px;

  svg {
    width: 40px;
    height: 40px;
  }

  @media (${query.above.phone}) {
    width: 66px;
    height: 66px;

    svg {
      width: 66px;
      height: 66px;
    }
  }
`;

export const Button = styled(ButtonLink)`
  padding: 0 auto;
  width: 100%;
  font-size: 14px;
  line-height: 22px;
  ${fonts.sofiaRegular};
  font-weight: 700;

  @media (${query.above(500)}) {
    padding: 0 45px;
    width: auto;
    font-size: 16px;
    line-height: 16px;
  }
`;

export const ButtonGroup = styled.div`
  text-align: center;
  padding: 0 20px;
`;

const Confirmation = (props: RawPageProps) => {
  return (
    <Page {...props.pageContext} pagePath={props.path}>
      <Container>
        <Inner>
          <ConfirmationIconWrapper>
            <StyledConfirmationIcon />
          </ConfirmationIconWrapper>
          <Headline>Request confirmed.</Headline>
          <Description as={P1}>
            Your Do Not Sell My Personal Information request has been confirmed.
          </Description>
          <ButtonGroup>
            <Button
              href="/"
              buttonText="Return to Home"
              openInNewWindow={false}
              color="lightYellow"
              textColor="charcoal"
            />
          </ButtonGroup>
        </Inner>
      </Container>
    </Page>
  );
};

export default Confirmation;
